import React from "react";
import CustomLink from "@/components/custom-link-react";
import Text, { TEXT_COLOR, TEXT_WRAP } from "@gcloud-npm/components.text";
import "./styles.scss";


const renderPills = ({ items2render, maybeLogged }) => {
  return (
    <>
      <div className="header4-section">
        <div className="container">
          <ul className="pill-section" data-cy="Header pills">
            {items2render.map((item, index) => {
              const link = item.link ? item.link : "#";
              const text = item.text;
              const active = item.selected;
              let h1OnSelectedPill = {};
              let textProps = {
                size: "caption01",
                fontType: "font-regular",
                color: TEXT_COLOR.GREY5,
                wrap: TEXT_WRAP.NOWRAP,
              };

              if (active) {
                textProps = {
                  ...textProps,
                  color: TEXT_COLOR.BLUE_DARK,
                };
              }
              if (item && item.h1OnSelectedPill) {
                ({ h1OnSelectedPill } = item);
              }
              const taggingInfo = {
                componentName: "header",
                componentContent: text,
                componentVariation: "tab",
                componentPerso: "",
                componentProduct: "",
              };

              return (
                <li>
                  <CustomLink
                    key={`item-${text}-${index}`}
                    className={`pill-item ${active ? "tab_active" : "tab"}`}
                    href={link}
                    taggingInfo={taggingInfo}
                  >
                    <Text text={text} {...textProps} {...h1OnSelectedPill} />
                    {maybeLogged && <div className="pill-bread-skeleton" />}
                  </CustomLink>
                </li>
              );
            })}
          </ul>
        </div>
        {/* INJECT_STYLED_JSX_TAG */}
      </div>
    </>
  );
};

const Header4Section = ({ pills, pillsLogged, logged, h1OnSelectedPill }) => {
  if (!pills) {
    return null;
  }
  let maybeLogged = false;
  let items2render = pills;
  if (pillsLogged && logged === 0) {
    maybeLogged = true;
  } else if (logged !== 0) {
    maybeLogged = false;
  }

  if (pillsLogged && logged) {
    items2render = pillsLogged;
  }
  return renderPills({ items2render, maybeLogged, h1OnSelectedPill });
};
export default Header4Section;
