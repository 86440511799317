import React, { Component } from "react";
import Logo from "@/components/header/components/logo";
import CustomLink from "@/components/custom-link-react";
import CloseButton from "@/components/header/components/close-button";
import IconGlyph from "@gcloud-npm/components.icon-glyph";

import "@/styles/components/header-light.scss";

class HeaderLight extends Component {
  constructor() {
    super();
  }
  componentDidUpdate() {
    try {
      const appElement = document.getElementById("header-root-plan-r");
      const referenceElement = document.getElementById("header-app-rr");
      if (referenceElement && referenceElement.offsetHeight) {
        appElement.style.minHeight = `${referenceElement.offsetHeight}px`;
      }
    } catch (err) {}
  }

  componentDidMount() {
    try {
      const appElement = document.getElementById("header-root-plan-r");
      const referenceElement = document.getElementById("header-app-rr");
      if (referenceElement && referenceElement.offsetHeight) {
        appElement.style.minHeight = `${referenceElement.offsetHeight}px`;
      }
    } catch (err) {}
  }

  render() {
    const { links, breadcrumbs } = this.props;
    const linkPrev =
      breadcrumbs && breadcrumbs["links"] && breadcrumbs["links"]["previous"];
    const linkClose =
      breadcrumbs && breadcrumbs["links"] && breadcrumbs["links"]["close"];
    return (
      <div className="header">
        <div className="header-light">
          <div className="menu">
            {(links?.["previous"] || linkPrev) && (
              <div
                className="user-icon"
                onClick={() =>
                  (window.location.href = links["previous"] || linkPrev)
                }
              >
                <CustomLink
                  onClick={() =>
                    (window.location.href = links?.["previous"] || linkPrev)
                  }
                  title="goBack"
                  ariaLabel="goBack"
                  className="button"
                  taggingInfo={{
                    componentName: "header-light",
                    componentContent: "goBackHeaderLight",
                    componentVariation: "all",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                >
                  <IconGlyph id="cheuron-left-m" width="20px" color="grey6" />
                </CustomLink>
              </div>
            )}
          </div>
          <div className="logo">
            <Logo />
          </div>
          <div className="options">
            {(links?.["close"] || linkClose) && (
              <div className="close">
                <CloseButton
                  onClick={() =>
                    (window.location.href = links?.["close"] || linkClose)
                  }
                  taggingInfo={{
                    componentName: "headerLight",
                    componentContent: "closeHeaderLight",
                    componentVariation: "all",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderLight;
