import React, { Suspense, useEffect, useState } from "react";
import Header from "./header.js";
import { useDispatch, useSelector } from "react-redux";
import { actions as headerActions } from "@/reducers/header-info-reducer";
import { actions as modalActions } from "@/reducers/modals-reducer";
import { actions as mainActions } from "@gcloud-npm/reducers.main-reducer";
import { mustBeShownHeader, mustBeShownHeaderLight } from "./utils/utils";
import { persistedInfo } from "./utils/persistedInfo";
import HeaderLight from "./components/headerLight";
import { createBrowserHistory } from "history";
import isWebview from "@gcloud-npm/utils.common.is-webview";
import Modal from "@/components/modal";

function App() {
  const HeaderInfo = useSelector((state) => state.headerInfo);
  const contextInfo = useSelector((state) => state.main.context);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(headerActions.startLoadInfo(window.location));
    // analizamos persistido y lanzamos en consecuencia
    const persisted = persistedInfo();
    if (persisted?.geo) {
      dispatch(mainActions.setCpParams(persisted.geo));
    } else {
      dispatch(mainActions.checkCPAndSetZones({}));
    }

    // definimos las subscripciones, algo en vcm corta la forma buena de hacerlo
    const history = createBrowserHistory();
    history.listen(() => {
      dispatch(headerActions.startNookInfoHeader({ typeNook: "refresh" }));
    });
    window.nookInfoExchange.registerAsObserver(
      "HeaderEnvironment",
      `Headerenvironment-${Date.now()}`,
      (info) => {
        if (info) {
          dispatch(
            headerActions.startNookInfoHeader({
              info,
              typeNook: "headerEnvironment",
            })
          );
        }
      }
    );
    window.nookInfoExchange.registerAsObserver(
      "Context",
      `Headercontext-${Date.now()}`,
      (info) => {
        if (info) {
          dispatch(
            headerActions.startNookInfoHeader({ info, typeNook: "context" })
          );
        }
      }
    );
    window.nookInfoExchange.registerAsObserver(
      "Location-neo",
      "HeaderRefresh",
      (info) => {
        if (info) {
          dispatch(headerActions.startNookInfoHeader({ typeNook: "refresh" }));
        }
      }
    );
  }, []);

  const mustBeShown = mustBeShownHeader(HeaderInfo);
  const mustBeShownLight = mustBeShownHeaderLight(HeaderInfo);

  if (mustBeShown) {
    if (mustBeShownLight) {
      return (
        <div className="App">
          {HeaderInfo && <HeaderLight {...HeaderInfo} />}
        </div>
      );
    }
    return (
      <div id="header-app-rr" className="App">
        {HeaderInfo && HeaderInfo.mainOptions && !isWebview() && (
          <Header {...HeaderInfo} context={contextInfo} />
        )}
        <Suspense fallback={<div></div>}>
          <Modal
            addModal={(data) => {
              dispatch(modalActions.addModal(data));
            }}
          />
        </Suspense>
      </div>
    );
  } else {
    return null;
  }
}

export default App;
