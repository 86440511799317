import React, { Component } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

export const STICKYTYPES = {
  DYNAMICTOP: "dynamicTop",
  DYNAMICBOTTOM: "dynamicBottom",
  STATICTOP: "staticTop",
  STATICBOTTOM: "staticBottom",
};

export const POSITION = {
  DYNAMIC: "DYNAMIC",
  STATIC: "STATIC",
};

export const BEHAVIOUR = {
  ALL_STICKY: "ALL_STICKY",
  STATIC_TOP: "STATIC_TOP",
};

class Sticky extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fixed: true,
      height: undefined,
      elem: undefined,
    };
  }

  componentDidMount() {
    this.setState(this.getFixed());
    window.addEventListener("scroll", this.scrollHandler, false);
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("scroll", this.handleScroll);
    } catch {
      console.error("handleScroll is not defined");
    }
  }

  componentDidUpdate() {
    switch (this.state.fixed) {
      case true:
        document.body.style.paddingTop = `${this.state.height}px`;

        this.state.elem.style.position = "fixed";
        this.state.elem.style.top = "0px";
        break;

      case false:
        document.body.style.paddingTop = `0px`;
        this.state.elem.style.position = "static";
        break;
    }
  }

  // FUNCTIONS
  getFixed = () => {
    const elem = document.querySelector(".Sticky");
    if (!elem && !this.state.elem) return undefined;

    const { height } = elem.getBoundingClientRect();
    const scrollY = window.scrollY;

    switch (this.props.behaviour) {
      case "ALL_STICKY":
        return { fixed: scrollY >= 0, height: height, elem: elem };

      default:
        return { fixed: scrollY > height, height: height, elem: elem };
    }
  };

  scrollHandler = () => {
    const newFixed = this.getFixed();

    if (newFixed && this.state.fixed !== newFixed.fixed) {
      this.setState(newFixed);
    }
  };

  render() {
    const stickyClass = `Sticky ${this.props.className} `;

    return (
      <>
        <div className={stickyClass}>{this.props.children}</div>
        {/* INJECT_STYLED_JSX_TAG */}
      </>
    );
  }
}

Sticky.propTypes = {};

Sticky.defaultProps = {
  type: STICKYTYPES.DYNAMICTOP,
  className: "",
  position: POSITION.DYNAMIC,
  behaviour: BEHAVIOUR.STICK_TO_TOP,
};

export default Sticky;
