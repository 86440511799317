import { put, call, select, all } from "redux-saga/effects";
import getStaticContent from "@gcloud-npm/adabyron.misc.get-static-content";
import getUrlParams from "@gcloud-npm/utils.common.get-url-params";
import { actions as headerActions } from "@/reducers/header-info-reducer";
import { actions as mainActions } from "@gcloud-npm/reducers.main-reducer";
import secureLocalStorage from "@gcloud-npm/utils.secure.local-storage";
import getByName from "@gcloud-npm/adabyron.custo.get-by-name";
import { normalizerData } from "@/utils/normalizer";
import { checkUrlBreadcrumbs } from "@/utils/utils";

function loadUrlParams(search) {
  if (search && window?.location?.href) {
    const urlParams = getUrlParams({
      groupByObject: true,
      allowedParams: null,
      currentUrl: window.location.href,
    });

    return urlParams;
  }
}

const NEW_APP_ACCOUNT = {
  inicio: {
    text: "Mis productos",
    link: "/area-cliente/mis-productos",
  },
  facturas: {
    text: "Facturas",
    link: "https://www.movistar.es/cliente/areaprivada/#/facturas",
  },
  gestiones: {
    text: "Gestiones",
    link: "/area-cliente/gestiones",
  },
  consumos: {
    text: "Consumos",
    link: "/area-cliente/mis-productos/consumos",
  },
  perfil: {
    text: "Mi perfil",
    link: "/area-cliente/mi-perfil",
  },
  "atencion-cliente": {
    text: "Atención al cliente",
    link: "https://www.movistar.es/atencion-cliente/ayuda",
  },
};

const CUSTO_ACTIVITY = "HEADER_BURGER_MENU_ACTIVITY";
const CUSTO_VERSION = "3";

export const getMarkNewAP = (persistedInfo) => {
  const markList =
    persistedInfo &&
    persistedInfo.userPq &&
    persistedInfo.userPq.ml &&
    persistedInfo.userPq.ml;
  let nAP = false;
  if (
    markList &&
    Array.isArray(markList) &&
    markList.length > 0 &&
    markList.includes("nAP")
  ) {
    nAP = true;
  }
  return nAP;
};

export function* loadJsonHeader(location) {
  try {
    const secureLocal = new secureLocalStorage();
    const persistedInfo = secureLocal.getItem("ts");

    const contextFromReducerSelector = (state) => state.main.context;
    let context = yield select(contextFromReducerSelector);
    const userPqFromReducerSelector = (state) => state.main.userPq;
    const userPq = yield select(userPqFromReducerSelector);
    const userPoFromReducerSelector = (state) => state.main.userPo;
    const userPo = yield select(userPoFromReducerSelector);
    const userRecomFromReducerSelector = (state) => state.main.userRecom;
    const userRecom = yield select(userRecomFromReducerSelector);

    const promisesArray = [
      call(getByName, {
        name: CUSTO_ACTIVITY,
        context,
        kind: "activity",
        userPq,
        userPo,
        userRecom,
        custoVersion: CUSTO_VERSION,
      }),
      call(getStaticContent, {
        context,
        resourcePath: "/static/json/header/breadcrumbs.json",
        build: false,
      }),
    ];

    const data = yield all(promisesArray);

    const mark = getMarkNewAP(persistedInfo);

    let { pathname, search, hash } = location.location;
    // check cuando hash tenga search
    if (hash.indexOf("?") != -1) {
      const hashWithoutSearch = hash.substr(0, hash.indexOf("?"));
      const searchFromHash = hash.substring(hashWithoutSearch.length);
      hash = hashWithoutSearch;
      search = searchFromHash;
    }
    if (search) {
      const paramsFromUrl = loadUrlParams(search);
      if (paramsFromUrl && Object.keys(paramsFromUrl).length !== 0) {
        if (paramsFromUrl?.url)
          paramsFromUrl.url = checkUrlBreadcrumbs(paramsFromUrl.url, hash);
        context = { ...context, ...paramsFromUrl };
      }
    }
    let urlBreadcrumbs = !pathname ? "/" : pathname;
    urlBreadcrumbs = checkUrlBreadcrumbs(pathname, hash);
    const dataFromNormalicer = normalizerData(data, context, urlBreadcrumbs);
    if (mark) {
      let accountValue = {};
      const accountsObject = Object.keys(NEW_APP_ACCOUNT);
      accountsObject.forEach((key, index) => {
        accountValue[index] = NEW_APP_ACCOUNT[key];
      });
      dataFromNormalicer.account = accountValue;
    }
    yield put(headerActions.setLoadInfo(dataFromNormalicer));
  } catch (error) {
    console.log(error);
  }
}

export function* checkCPAndSetZones({ data }) {
  try {
    const { cp, needBussinesZone } = data;
    let module = yield call(() =>
      import("@gcloud-npm/adabyron.geo.get-cp-zone")
    );
    module = module.default;

    const lastCP = yield select((state) => state.main.cp);
    const context = yield select((state) => state.main.context);

    // need get again bussinessZones, cuando montamos el modal con datos ya persistidos y no tenmoes la lista de municipios
    if (needBussinesZone) {
      yield put(mainActions.setCheckedCPByApi(false));
      let response = yield call(module, { cp, context });
      const { businessZones } = response;
      const lastZone = yield select((state) => state.main.zone);
      const lastMunicipio = yield select((state) => state.main.municipio);

      response = {
        cp: lastCP,
        zone: lastZone,
        municipio: lastMunicipio,
        businessZones,
      };
      yield put(mainActions.setCheckedCPByApi(true));
      yield put(mainActions.setCpParams(response));
    }

    // regular behaviour
    if (!needBussinesZone && lastCP !== cp) {
      yield put(mainActions.setCheckedCPByApi(false));
      let response = yield call(module, { cp, context });
      let zone = (response && response.zone) || "ZL";
      let municipio = null;
      // getting first result and using its zone
      if (
        response &&
        response.businessZones &&
        Array.isArray(response.businessZones) &&
        response.businessZones.length > 0
      ) {
        const { businessZoneName } = response.businessZones[0];
        zone = businessZoneName;
        municipio = response.businessZones[0];
      }

      response = {
        ...response,
        cp: response.postalCode,
        zone,
        municipio,
      };
      delete response.postalCode;
      yield put(mainActions.setCpParams(response));
      yield put(mainActions.setCheckedCPByApi(true));
    }
  } catch (err) {
    yield put(mainActions.setCheckedCPByApi(false));
  }
}
