export const OPACITY_LAYOUT_COLORS = {
  BLACK: "black",
  WHITE: "white",
  DARKBLUE: "darkblue",
  BLACK05: "black05",
  TRANSPARENT: "transparent",
  TOTAL_WHITE: "totalWhite",
};

// XPOSITION_ YPOSITION
export const CONTENT_POSITION = {
  // absolutelly center
  CENTER_CENTER: "center--center",
  LEFT_CENTER: "left--center",
  RIGHT_CENTER: "right--center",
  // vertically top
  CENTER_TOP: "center--top",
  RIGHT_TOP: "right--top",
  LEFT_TOP: "left--top",
  // vertically bottom
  CENTER_BOTTOM: "center--bottom",
  RIGHT_BOTTOM: "right--bottom",
  LEFT_BOTTOM: "left--bottom",
  CUSTOM: "custom",
};

export const IN_SCENE_EFFECT = {
  FADE_IN: "fadeIn",
  FADE_IN_LEFT: "fadeInLeft",
  FADE_IN_RIGHT: "fadeInRight",
  FADE_IN_TOP: "fadeInTop",
  FADE_IN_BOTTOM: "fadeInBottom",
};

export const OUT_SCENE_EFFECT = {
  FADE_OUT: "fadeOut",
  FADE_OUT_LEFT: "fadeOutLeft",
  FADE_OUT_RIGHT: "fadeOutRight",
  FADE_OUT_TOP: "fadeOutTop",
  FADE_OUT_BOTTOM: "fadeOutBottom",
};
