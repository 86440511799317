import React from "react";
import CustomLink from "@/components/custom-link-react";
import Text from "@gcloud-npm/components.text";
import "./styles.scss";

const renderElemnts = (items, title, skeletonClassName) => {
  let i = 0;
  return (
    <>
      <nav>
        <ul className="breadcrumb" data-cy="Header breadcrumb">
          {items.map(([key, value]) => {
            const isReact = value && value.indexOf("react:") !== -1;
            const text = key;
            let link = value ? value : "#";
            if (isReact) {
              link = link.replace("react:", "");
            }
            return (
              <li
                key={`item-${i++}`}
                className={`breadcrumb-item breacrumb-item-value-${text.toLowerCase()}`}
              >
                <CustomLink
                  href={!isReact ? link : null}
                  title=""
                  ariaLabel=""
                  onClick={
                    isReact
                      ? () => {
                          let id2click = `#react-${key}`;
                          id2click = id2click.replace(/ /g, "");
                          document.querySelector(id2click).click();
                        }
                      : null
                  }
                  taggingInfo={{
                    componentName: "header",
                    componentContent: text,
                    componentVariation: "breadcrumb",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                >
                  <Text
                    text={text}
                    fontType="font-regular"
                    size="subtitle2"
                    color="grey6"
                    display="inline"
                  />
                </CustomLink>
                <div className={skeletonClassName} />
              </li>
            );
          })}

          <li className="breadcrumb-item active">
            <Text
              text={title}
              fontType="font-regular"
              size="subtitle2"
              color="grey5"
              display="inline"
            />
            <div className={skeletonClassName} />
          </li>
        </ul>
      </nav>
    </>
  );
};

const Breadcrumb = (props) => {
  const { breadcrumb, extraBreadcrumbsProps, skeletonClassName } = props;
  if (!breadcrumb) {
    return null;
  }

  let items = Object.entries(breadcrumb["breadCrumbs"]);
  if (extraBreadcrumbsProps) {
    const another = Object.entries(extraBreadcrumbsProps);
    items.push(another[0]);
  }
  return <>{renderElemnts(items, breadcrumb.title, skeletonClassName)}</>;
};
export default Breadcrumb;
