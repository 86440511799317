import { call, put } from "redux-saga/effects";
import { actions as headerActions } from "@/reducers/header-info-reducer";
import { actions as mainActions } from '@gcloud-npm/reducers.main-reducer';
import { loadJsonHeader } from '../../header-info-saga';
import secureStorage from '@gcloud-npm/utils.secure.local-storage';
import { hanTranscurridoSeisMeses } from "../../../utils/utils";


export function* startNookInfoHeader({ data }) {
    const nookInfo = {
        info: null,
        typeNook: ''
    }
    if (data && data.info) {
        nookInfo.info = data.info
    }
    if (data && data.typeNook) {
        nookInfo.typeNook = data.typeNook
    }

    // yield put para guardar la nueva info en la store
    // switch para diferenciar nook subscription
    switch (nookInfo?.typeNook) {
        case 'headerEnvironment':
            yield put(headerActions.setNookInfoHeader(nookInfo.info));
            break;
        case 'context':
            let finalRes = {}
            let contextInfo = (nookInfo && nookInfo.info) || {};
            let persistedInfo2Use = {};
            // comprobar la persistencia y combinar esos cambios con lo que nos diga nookinfo
            const secureStorageLib = new secureStorage();
            let persistedInfo = secureStorageLib.getItem('ts');
            if (persistedInfo && persistedInfo.ts) {
                // check de los 6 meses
                const currentDate = Date.now();
                const lastTs = persistedInfo && persistedInfo.ts;
                const checkSeisMeses = hanTranscurridoSeisMeses(lastTs, currentDate);
                if (checkSeisMeses) {
                    persistedInfo = {};
                    secureStorageLib.removeItem('ts');
                }
                if (persistedInfo && persistedInfo.userName && persistedInfo.userType) {
                    persistedInfo2Use = {
                        userName: persistedInfo.userName,
                        userType: persistedInfo.userType,
                    }
                }
            }
            finalRes = {
                ...contextInfo,
                ...persistedInfo2Use
            }
            yield put(mainActions.setContextData(finalRes));
            break;
        case 'refresh':
            yield call(loadJsonHeader, { location: window.location });
            break;

        default:
            break;
    }
}