import { call, put, select } from "redux-saga/effects";
import { actions as modalActions } from "@/reducers/modals-reducer";
import Modal from "@/components/modal";
let history = null;
let anchorModule = null;
let addModalModule = null;
let startedModalListener = false;

export function* initAnchorModalListener() {
  if (!anchorModule) {
    anchorModule = yield call(() => import("./anchor-modal-listener-module"));
  }
  yield call(anchorModule.initAnchorModalListenerModule, {
    end: false,
    history,
  });
}

export function* omitAnchorListernerOneTime(flag) {
  if (!anchorModule) {
    anchorModule = yield call(() => import("./anchor-modal-listener-module"));
  }
  yield call(anchorModule.setOmitAnchorListernerOneTime, flag);
}

let localModal = null;

export function* addModal({ data }) {
  if (!history) {
    history = yield call(() => import("history"));
    history = history.createBrowserHistory();
    if (!startedModalListener) {
      startedModalListener = true;
      yield put(modalActions.initAnchorModalListener());
    }
  }
  if (data && data.omitAnchorListener) {
    yield call(omitAnchorListernerOneTime, true);
  } else {
    yield call(omitAnchorListernerOneTime, false);
  }
  // hacemos el import
  if (!addModalModule) {
    addModalModule = yield call(() => import("./add-modal"));
  }
  let retries = 5;
  let Modal = yield select((state) => state.modals.Modal);
  while (!Modal && retries > 0) {
    retries = retries - 1;
    Modal = yield select((state) => state.modals.Modal);
  }
  yield call(addModalModule.default, { ...data, history });
}
