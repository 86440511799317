import React from "react";
import DefaultMenu from "../../menu/layouts/default";
import CPMenu from "../../menu/layouts/cp";
import { MENU_LAYOUTS } from "../../textConstans";
import "./styles.scss";


const Header2Nav = (props) => {
  const {
    logged,
    cp,
    navSections,
    segmentMenu,
    municipios,
    municipio,
    showCP,
    forceSelect,
  } = props;
  const selector = logged ? "logged" : "default";
  return (
    <div className="container">
      <div className="header2-nav">
        <div className="menu">
          <ul className="section-menu" data-cy="Header links">
            {navSections && navSections[selector] && (
              <DefaultMenu
                items={navSections[selector]}
                layout={MENU_LAYOUTS.DEFAULT}
                forceSelect={forceSelect}
                location="main"
              />
            )}
          </ul>
          <ul className="segment-menu" data-cy="Header secondary links">
            {segmentMenu && segmentMenu[selector] && (
              <DefaultMenu
                items={segmentMenu[selector]}
                layout={MENU_LAYOUTS.DEFAULT}
                forceSelect={forceSelect}
                location="main"
              />
            )}
          </ul>
        </div>
        {showCP && (
          <ul className="options">
            <CPMenu
              layout={MENU_LAYOUTS.CP}
              location="menu"
              cp={cp}
              municipios={municipios}
              municipio={municipio}
            />
          </ul>
        )}
      </div>
      {/* INJECT_STYLED_JSX_TAG */}
    </div>
  );
};
export default Header2Nav;
