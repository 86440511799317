import React from "react";
import CustomLink from "@/components/custom-link-react";
import IconGlyph from "@gcloud-npm/components.icon-glyph";
import { TEXT_COLOR } from "@gcloud-npm/components.text";

export const Logo = (props) => {
  return (
    <CustomLink
      href="https://www.movistar.es"
      title="Movistar"
      ariaLabel="Ir a la home"
      taggingInfo={{
        componentName: "header",
        componentContent: "LogoM",
        componentVariation: "all",
        componentPerso: "",
        componentProduct: "",
      }}
      data-cy="Header Movistar logo"
    >
      <IconGlyph
        className="visible-sm movistar-m"
        id="Movistar-m"
        width={38}
        color={TEXT_COLOR.BLUE_MOVISTAR}
        priority
      />
      <IconGlyph
        className="hidden-sm medium movistar-m"
        id="Movistar-letra-m"
        height={26.73}
        width={112}
        color={TEXT_COLOR.BLUE_MOVISTAR}
        priority
      />
      <IconGlyph
        className="hidden-sm big movistar-m"
        id="Movistar-letra-m"
        height={32}
        width={134}
        color={TEXT_COLOR.BLUE_MOVISTAR}
        priority
      />
    </CustomLink>
  );
};

export default Logo;
