import React, { Component } from "react";
import { connect } from "react-redux";
//import dynamic from "next/dynamic";
import CustomLink from "@/components/custom-link-react";
import Text from "@gcloud-npm/components.text";
import IconGlyph from "@gcloud-npm/components.icon-glyph";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import { actions as modalActions } from "@/reducers/modals-reducer";
import { actions as mainActions } from "@gcloud-npm/reducers.main-reducer";

class CPMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      device: null,
    };
  }

  setDevice = () => {
    this.setState({ device });
  };

  handleCpModal = () => {
    const device = getDeviceKindDependsOnViewPort();
    const { addModal, cp, municipios, municipio, setCpModalClosed } =
      this.props;
    const CPModal = React.lazy(() => import("../../../modals/cp"));

    let modalProps = {
      contentPosition: "center--bottom",
      opacityLayoutColor: "blur",
      inSceneEffect: "fadeInBottom",
      contentInsideContainer: false,
      outSceneEffect: "fadeOutBottom",
      closeByClickingOutside: true,
      closeIdByClickingOutside: "",
      onCloseHandler: () => {
        setCpModalClosed(true);
      },
    };

    if (device !== "MOBILE") {
      modalProps = {
        ...modalProps,
        contentPosition: "right--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeInBottom",
        contentInsideContainer: true,
        outSceneEffect: "fadeOutBottom",
        closeByClickingOutside: true,
        closeIdByClickingOutside: "",
        createAnchor: false,
      };
    }
    setCpModalClosed(false);
    addModal({
      id: "cp",
      Element2Show: CPModal,
      modalProps,
      elementProps: {
        cp: cp,
        municipio: municipio,
        municipios: municipios,
      },
    });
  };

  render() {
    const { cp } = this.props;
    return (
      <li>
        <CustomLink
          title={`Ofertas para ${!cp ? "..." : cp}`}
          ariaLabel={`Ofertas para ${!cp ? "..." : cp}`}
          onClick={this.handleCpModal}
          taggingInfo={{
            componentName: "header",
            componentContent: "openCP",
            componentVariation: "all",
            componentPerso: "",
            componentProduct: "",
          }}
        >
          <div
            key={`Item-${"Ofertas"}`}
            className={`menu-item ${"Ubicacion"}`}
            data-cy="Header ubicacion"
          >
            <IconGlyph color="grey6" id="ubicacion-s" width={15} />
            <div className="text-gap">
              <Text
                text={`Ofertas para ${!cp ? "..." : cp}`}
                align="center"
                fontType="font-regular"
                size="subtitle2"
                color="grey6"
                display="block"
                border="none"
              />
            </div>
          </div>
        </CustomLink>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  cp: state.main.cp,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
  setCpModalClosed: (flag) => dispatch(mainActions.setCpModalClosed(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CPMenu);
