/* eslint-disable prettier/prettier */
import React from "react";
import Text from "@gcloud-npm/components.text";
import "./styles.scss";


const Header6Title = (props) => {
  const { title, theme, textProps, loadingHeaderLocation } = props;
  if (!theme) {
    return null;
  }
  let fontType, size, color;
  switch (theme) {
    case "areaprivada":
      size = "title02";
      color = "white";
      fontType = "font-regular";
      break;
    case "detalle":
      size = "title02";
      color = "dark-blue";
      fontType = "font-bold";
      break;

    default:
      size = "title03";
      color = "dark-blue";
      fontType = "font-bold";
  }

  let skeletonClassName = "skeleton loaded";
  if (loadingHeaderLocation) {
    skeletonClassName = "skeleton";
  }

  return (
    <>
      <div className="header6-title">
        <div className={theme}>
          <div className="container">
            <Text
              {...textProps}
              text={title}
              fontType={fontType}
              size={size}
              color={color}
            />
            <div className={skeletonClassName} />
          </div>
        </div>
        {/* INJECT_STYLED_JSX_TAG */}
      </div>
    </>
  );
};
export default Header6Title;
