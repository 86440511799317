import { combineReducers } from 'redux';
import mainReducer from '@gcloud-npm/reducers.main-reducer';
import modalsReducer from '@/reducers/modals-reducer';
import headerInfoReducer from './header-info-reducer';
import headerReducer from '@gcloud-npm/reducers.header-reducer';
const REDUCERS_LIST = {
  main: mainReducer,
  modals: modalsReducer,
  header: headerReducer,
  headerInfo: headerInfoReducer,
}

const rootReducers = () =>
  combineReducers({
    ...REDUCERS_LIST,
  });

export default rootReducers;
