import React, { Component } from "react";
//import dynamic from "next/dynamic";
import CustomLink from "@/components/custom-link-react";
import Text from "@gcloud-npm/components.text";
import IconGlyph from "@gcloud-npm/components.icon-glyph";
import { connect } from "react-redux";
import { actions as modalActions } from "@/reducers/modals-reducer";
import getDeviceKindDependsOnViewPort from "@gcloud-npm/utils.common.get-device-kind-depends-on-viewport";
import "./styles.scss";

const ModalUser = React.lazy(() => import("../modals/user"));

class UserPill extends Component {
  constructor(props) {
    super(props);
  }

  openModal = () => {
    const { addModal, items, segmentMenu, options, userName, userType , logoutBackToHome, privateArea } =
      this.props;
    const device = getDeviceKindDependsOnViewPort();
    const fadeIn = device === "MOBILE" ? "fadeInTop" : "fadeInTop";
    const fadeOut = device === "MOBILE" ? "fadeOut" : "fadeOut";
    addModal({
      id: "account",
      Element2Show: ModalUser,
      modalProps: {
        contentPosition: "right--top",
        opacityLayoutColor: "blur",
        inSceneEffect: fadeIn,
        closeIdByClickingOutside: "",
        contentInsideContainer: true,
        outSceneEffect: fadeOut,
        closeByClickingOutside: true,
        createAnchor: false,
      },
      elementProps: {
        items: { ...items },
        userName: userName,
        userType: userType,
        logoutBackToHome: logoutBackToHome,
        privateArea: privateArea,
      },
    });
    this.setState({
      modalOpened: true,
    });
  };

  render() {
    const { userName } = this.props;

    return (
      <>
        <div className="user-pill">
          <CustomLink
            onClick={this.openModal}
            title="Mi cuenta"
            ariaLabel="Mi cuenta"
            className="button"
            taggingInfo={{
              componentName: "header",
              componentContent: "openAccount",
              componentVariation: "all",
              componentPerso: "",
              componentProduct: "",
            }}
          >
            <div className="user-icon">
              <IconGlyph color="white" id="usuario-m" width={20} height={20} />
            </div>
          </CustomLink>
          <div className="user-content">
            <div className="user-name"> 
                <Text
                    text={`Hola, ${userName}`}
                    size="caption"
                    display="block"
                  />
            </div>
            <div className="user-menu">
              <div className="item">
                <CustomLink
                  onClick={this.openModal}
                  title="Mi cuenta"
                  ariaLabel="Mi cuenta"
                  taggingInfo={{
                    componentName: "header",
                    componentContent: "openAccount",
                    componentVariation: "all",
                    componentPerso: "",
                    componentProduct: "",
                  }}
                >
                  <Text
                    text="Mi cuenta"
                    fontType="font-regular"
                    size="caption"
                    color="blue"
                    display="block"
                  />
                </CustomLink>
              </div>
            </div>
          </div>
          {/* INJECT_STYLED_JSX_TAG */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  modals: state.modals.modalHistory,
});

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPill);
