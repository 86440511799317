import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import isWebView from "@gcloud-npm/utils.common.is-webview";
import store from "./store";
import App from "./App";
/* [STYLES] begins */
import '@/styles/button.spin.scss';
import '@/styles/button.scss';
import '@/styles/c-image.scss';
import '@/styles/form.scss';
import '@/styles/label.scss';
import '@/styles/price.scss';
import '@/styles/spin.scss';
import '@/styles/text.scss';
import '@/styles/globals.scss';
import '@/styles/grid.scss';
import '@/styles/colors.scss';
import '@/styles/fonts.scss';
/* [STYLES] ends */
import { WebviewBridgeReact } from "./utils/utils";

// cargamos iconos de aplicaciioens de terceros
/* checking incons styles  */
const ICONS_CSS_FILENAME =
  "https://prod.cms.col.movistar.es/static/fonts/icon/icons.min.css?cache=040720221337";
const loadCssIcons = () => {
  let isAlreadyLoaded = false;
  const ss = document.styleSheets;
  for (let i = 0, max = ss.length; i < max; i += 1) {
    if (ss[i].href === ICONS_CSS_FILENAME) {
      isAlreadyLoaded = true;
      break;
    }
  }
  if (!isAlreadyLoaded) {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.async = "async";
    link.defer = "defer";
    link.href = ICONS_CSS_FILENAME;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
};
loadCssIcons();

const rootElement = document.getElementById("header-root-plan-r");
const root = createRoot(rootElement);

const disabledHeader = () => {
  try {
    const appElement = document.getElementById("header-root-plan-r");
    appElement.classList.add("disabled");
  } catch (err) {
    console.error(err);
  }
};

const isUnderWebView =
  window.location.href.indexOf("areaprivada/#/webviews") > -1;
if (isUnderWebView) disabledHeader();
const isUnderWebViewNovum = isWebView();
if (isUnderWebViewNovum) WebviewBridgeReact();
if (!isUnderWebView) {
  root.render(
    <Provider store={store}>
      <Suspense fallback={<div></div>}>
        <App />
      </Suspense>
    </Provider>
  );
}
