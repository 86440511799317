import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Modal from "@/components/modal";
import IconGlyph from "@gcloud-npm/components.icon-glyph";

const SupportAndSearch = React.lazy(() =>
  import("@/components/header/modals/support-search")
);

import { actions as headerActions } from "@gcloud-npm/reducers.header-reducer";
import { actions as modalActions } from "@/reducers/modals-reducer";

const INPUT_ID = "inputsupportadnsearch";
let QUERY = "";

export const checkIfThereAreResults = ({
  pagesSuggestedList,
  querySuggestedInputs,
  checkPages = true,
  checkAt = true,
}) => {
  let res = false;
  if (checkPages) {
    const thereArePages =
      pagesSuggestedList &&
      ((pagesSuggestedList.regular && pagesSuggestedList.regular.length > 0) ||
        (pagesSuggestedList.services &&
          pagesSuggestedList.services.length > 0) ||
        (pagesSuggestedList.devices && pagesSuggestedList.devices.length > 0));
    res = thereArePages;
  }

  if (checkAt) {
    const checkATInputs =
      querySuggestedInputs && querySuggestedInputs.length > 0;
    res = res || checkATInputs;
  }
  return res;
};

export const handleAtRequest = ({
  label,
  event,
  checkPages,
  checkAt,
  pagesSuggestedList = null,
  querySuggestedInputs = null,
}) => {
  try {
    const inputValue = document.getElementById(INPUT_ID).value;
    let query = label || inputValue;
    if (event) {
      event.preventDefault();
    }
    const detectAutocompleteOption =
      document.getElementById("hidden-query").value;
    const shouldSend = !checkIfThereAreResults({
      checkPages,
      checkAt,
      pagesSuggestedList,
      querySuggestedInputs,
    });
    if (
      (shouldSend || detectAutocompleteOption) &&
      inputValue &&
      inputValue.length > 0
    ) {
      query = encodeURIComponent(query);
      const name = "searcher";
      const content = encodeURIComponent(`${QUERY}`);
      const variation = detectAutocompleteOption
        ? "asistente-virtual"
        : "asistente-virtual-no-results";
      const urlToRedirect = `https://atencionalcliente.movistar.es/?q=${query}&name=${name}&content=${content}&variation=${variation}`;
      window.location.href = urlToRedirect;
    }
  } catch (error) {}
};
class SearchInput extends Component {
  constructor(props) {
    super(props);
    this.resultModalOpen = false;
    this.state = {
      query: "",
      focus: false,
    };
  }

  setResultModalOpen = (flag) => {
    this.resultModalOpen = flag;
  };

  setUp = () => {
    const { getSearcherInfo, pagesList } = this.props;
    if (!pagesList) {
      getSearcherInfo();
    }
  };

  componentDidMount = () => {
    const { autoFocus } = this.props;
    if (autoFocus) {
      // hacemos timeout para que funcione en safari
      setTimeout(() => {
        try {
          document.getElementById(INPUT_ID).focus();
        } catch (err) {}
        try {
          document.getElementById(INPUT_ID).click();
        } catch (err) {}
      }, 500);
      this.setUp();
    }
  };

  onCloseHandler = () => {
    this.setResultModalOpen(false);
    this.handleClear({
      withCloseModal: true,
      withQueryReset: true,
    });
    const { addModal } = this.props;
    addModal({
      id: "closeAll",
    });
  };

  openSearchModal = () => {
    this.setResultModalOpen(true);

    const { addModal } = this.props;
    addModal({
      id: "searcher",
      Element2Show: SupportAndSearch,
      modalProps: {
        contentPosition: "center--top",
        opacityLayoutColor: "blur",
        inSceneEffect: "fadeIn",
        contentInsideContainer: true,
        outSceneEffect: "fadeOut",
        closeAllOnClickOutside: true,
        closeByClickingOutside: true,
        onCloseHandler: this.onCloseHandler,
        createAnchor: false,
      },
      elementProps: {
        visibleInput: false,
      },
    });
  };

  handleSearch = (query) => {
    const {
      getRelatedPages,
      onSearch,
      needOpenModalResults,
      pagesSuggestedList,
      querySuggestedInputs,
    } = this.props;
    // buscamos por paginas
    getRelatedPages(query);
    // comunicamos a quien lo necesite
    if (onSearch) {
      onSearch(query);
    }
    // gestion de modal
    if (needOpenModalResults && !this.resultModalOpen) {
      const shouldOpenModal = checkIfThereAreResults({
        checkPages: true,
        checkAt: true,
        pagesSuggestedList,
        querySuggestedInputs,
      });

      if (shouldOpenModal) {
        this.openSearchModal();
      }
      const { autoFocus } = this.props;
      if (autoFocus) {
        document.getElementById(INPUT_ID).focus();
      }
    }
  };

  onInputClick = () => {
    this.setUp();
    this.setState({
      focus: true,
    });
  };
  onBlurClick = () => {
    this.setState({
      focus: false,
    });
  };

  handleClear = ({ withCloseModal = false, withQueryReset = true }) => {
    const { resetSearcher, onSearch, addModal, backButton } = this.props;
    if (withQueryReset) {
      QUERY = "";
      this.setState({ query: "" });
    }
    if (onSearch) {
      onSearch("");
    }
    resetSearcher();
  };

  handleInputChange = (event) => {
    const query = event.target.value;
    if (query && query.length > 2) {
      this.handleSearch(event.target.value);
    } else {
      this.handleClear({
        withCloseModal: false,
        withQueryReset: false,
      });
    }
    QUERY = event.target.value;
    this.setState({
      query: event.target.value,
    });
  };

  render() {
    const { query, focus } = this.state;
    const {
      backButton,
      className,
      placeHolder,
      autoComplete,
      pagesSuggestedList,
      querySuggestedInputs,
    } = this.props;

    return (
      <form
        className="form-searcher"
        id={`form-${INPUT_ID}`}
        onSubmit={(event) => {
          handleAtRequest({
            event,
            checkAt: true,
            checkPages: true,
            pagesSuggestedList,
            querySuggestedInputs,
          });
        }}
      >
        <div
          className={`SearchInput${focus ? " focus" : ""}`}
          data-cy="Header search"
        >
          <button
            name="buscar"
            title="buscar"
            aria-label="buscar"
            type="submit"
          >
            <IconGlyph color="grey5" id="buscar-m" width={22} />
          </button>
          <input
            id={INPUT_ID}
            className={`${className ? ` ${className} ` : ""} field`}
            type="search"
            value={query}
            onClick={this.onInputClick}
            onBlur={this.onBlurClick}
            autoComplete={autoComplete}
            onChange={this.handleInputChange}
            placeholder={placeHolder}
            data-cy="Header input search"
          />
          <input id="hidden-query" type="hidden" />
          {backButton && (
            <button
              className="clearButton"
              type="button"
              onClick={() =>
                this.handleClear({
                  withCloseModal: false,
                  withQueryReset: true,
                })
              }
            >
              <IconGlyph color="grey6" id="cerrar-m" width={12} />
            </button>
          )}
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  querySuggestedInputs: state.header.querySuggestedInputs,
  pagesSuggestedList: state.header.pagesSuggestedList,
  pagesList: state.header.pagesList,
});

const mapDispatchToProps = (dispatch) => ({
  getSearcherInfo: () => dispatch(headerActions.getSearcherInfo()),
  addModal: (data) => dispatch(modalActions.addModal(data)),
  getSuggestedInputs: (query) =>
    dispatch(headerActions.getSuggestedInputs(query)),
  resetSearcher: () => dispatch(headerActions.resetSearcher()),
  getRelatedPages: (query) => dispatch(headerActions.getRelatedPages(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput);

SearchInput.propTypes = {
  backButton: PropTypes.bool,
  placeHolder: PropTypes.string,
  autoComplete: PropTypes.string,
  needOpenModalResults: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

SearchInput.defaultProps = {
  backButton: false,
  placeHolder: "Busca en Movistar",
  autoComplete: "off",
  needOpenModalResults: false,
  autoFocus: false,
};
