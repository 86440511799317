import { all, takeLatest, takeEvery } from "redux-saga/effects";
import { types as modalsAnchorActions } from "@/reducers/modals-reducer";
import { types as headerActions } from "../reducers/header-info-reducer";
import { types as mainActions } from "@gcloud-npm/reducers.main-reducer";

import {
  initAnchorModalListener,
  addModal,
} from "@/saga/modals-saga";
import { types as searcherTypes } from "@gcloud-npm/reducers.header-reducer";
import { getSearcherInfo, getRelatedPages } from "./header-saga";
import { checkCPAndSetZones, loadJsonHeader } from "./header-info-saga";
import { startNookInfoHeader } from "./header-info-saga/nook-info-saga";

function* rootSaga() {
  yield all([
    takeLatest(headerActions.LOAD_INFO, loadJsonHeader),
    /* REACT HEADER NOOK SUBSCRIPTION */
    takeEvery(headerActions.START_NOOK_INFO_DATA, startNookInfoHeader),
    /* REACT HEADER POSTAL CODE */
    takeLatest(mainActions.CHECK_CP_AND_SET_ZONES, checkCPAndSetZones),
    /* REACT HEADER SEARCH */
    takeLatest(searcherTypes.GET_SEARCHER_INFO, getSearcherInfo),
    takeLatest(searcherTypes.GET_RELATED_PAGES, getRelatedPages),
    /* REACT HEADER MODALS */
    takeLatest(
      modalsAnchorActions.INIT_ANCHOR_MODAL_LISTENER,
      initAnchorModalListener
    ),
    takeLatest(modalsAnchorActions.ADD_MODAL, addModal),
  ]);
}

export default rootSaga;
