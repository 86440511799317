import React from "react";
//import dynamic from'next/dynamic';
import { connect } from "react-redux";
const Label = React.lazy(() => import("@gcloud-npm/components.label"));
import CustomLink from "@/components/custom-link-react";
import { actions as modalActions } from "@/reducers/modals-reducer";
import Text from "@gcloud-npm/components.text";

const DefaultMenu = (props) => {
  if (!props["items"]) {
    return null;
  }
  const items = Object.values(props["items"]);
  const { location, addModal, forceSelect } = props;
  let i = 0;
  return (
    <>
      {items.map((item) => {
        let labelProps = (item && item["Label-0"]) || null;
        const link = item.link ? item.link : "#";
        const text = item.text;
        const ariaLabel = item.ariaLabel ? item.ariaLabel : item.text;
        const title = item.title ? item.ariaLabel : item.text;
        const reactLink = item.reactLink || false;
        var selectedItem = item.selected;

        if (forceSelect) {
          selectedItem = item.text === forceSelect;
        }
        if (
          !item.sections &&
          ((item && !item.location) || (item && item.location === location))
        ) {
          return (
            <li key={`item-${i++}`}>
              <CustomLink
                href={!reactLink ? link : null}
                title={title}
                ariaLabel={ariaLabel}
                onClick={
                  reactLink
                    ? () => {
                        try {
                          addModal({ id: "" });
                          setTimeout(() => {
                            document
                              .querySelector(`#react-${reactLink}`)
                              .click();
                          }, 500);
                        } catch (e) {
                          console.error(e);
                        }
                      }
                    : null
                }
                taggingInfo={{
                  componentName: "header",
                  componentContent: text,
                  componentVariation: location,
                  componentPerso: "",
                  componentProduct: "",
                }}
                cyData={`Header item ${text}`}
              >
                <div className={`menu-item ${selectedItem ? " selected" : ""}`}>
                  <Text
                    className="label-option"
                    text={text}
                    fontType="font-regular"
                    size="subtitle2"
                    color="grey5"
                    display="block"
                  />
                  {labelProps && !labelProps.hidden && (
                    <Label className="burger-label" {...labelProps} />
                  )}
                </div>
              </CustomLink>
            </li>
          );
        }
        return null;
      })}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  addModal: (data) => dispatch(modalActions.addModal(data)),
});

export default connect(null, mapDispatchToProps)(DefaultMenu);
