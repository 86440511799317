import React from "react";
import Logo from "../../logo";
import Searcher from "../../searcher";
import UserPill from "../../user-pill";
import BurgerMenu from "../../menu/layouts/burger";
import IconsMenu from "../../menu/layouts/icons";
import { MENU_LAYOUTS } from "../../textConstans";
import "./styles.scss";


const Header1Main = (props) => {
  const {
    logged,
    userName,
    mainOptions,
    segmentMenu,
    burgerSections,
    burgerOptions,
    accountSections,
  } = props;
  const selector = logged ? "logged" : "default";

  return (
    <div className="header1-main container">
      <div className="menu">
        <BurgerMenu
          layout={MENU_LAYOUTS.BURGER}
          items={burgerSections[selector]}
          segmentMenu={segmentMenu[selector]}
          options={burgerOptions[selector]}
          account={accountSections}
        />
      </div>
      <div className="logo">
        <Logo />
      </div>
      <div className="searcher">
        <Searcher needOpenModalResults />
      </div>
      <div className="options">
        <IconsMenu
          items={mainOptions[selector]}
          layout={MENU_LAYOUTS.ICONS}
          location="all"
        />
      </div>
      {logged && (
        <UserPill
          userName={userName}
          userType={logged}
          items={accountSections}
        />
      )}
    </div>
  );
};
export default Header1Main;
