import React from "react";
import Breadcrumb from "../../breadcrumb";
import "./styles.scss";


const Header3Breadcrumb = (props) => {
  const { breadcrumb, loadingHeaderLocation, extraBreadcrumbsProps } = props;

  let skeletonClassName = "skeleton loaded";
  if (loadingHeaderLocation) {
    skeletonClassName = "skeleton";
  }
  if (!breadcrumb) {
    return null;
  }

  return (
    <div className="container">
      <div className="header3-breadcrumb">
        <Breadcrumb
          skeletonClassName={skeletonClassName}
          breadcrumb={breadcrumb}
          extraBreadcrumbsProps={extraBreadcrumbsProps}
        />
      </div>
      {/* INJECT_STYLED_JSX_TAG */}
    </div>
  );
};
export default Header3Breadcrumb;
